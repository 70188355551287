import CMSAPI from './EndPoints';

export const getProject = async (name)=>{
  let endpoint = `${CMSAPI}/projects?name=${name}`;
  let data = await fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data)
    .catch(function(err) {
      console.log('Fetch Error: ', err);
    });
  return data[0];

}

export const getElements = async (project) =>{
  let endpoint = `${CMSAPI}/photos?project=${project}`;
  let data = await fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data)
    .catch(function(err) {
      console.log('Fetch Error: ', err);
    });
  return data;
}

export const getElementsById = async (idList) =>{
  let queryString = '/photos?';
  idList.map((idPhoto, idx)=>{
    if(idx == 0){
      queryString = queryString + `id_in=${idPhoto}`
    }else{
      queryString = queryString + `&id_in=${idPhoto}`
    }
  })
  let endpoint = `${CMSAPI}${queryString}`;
  let data = await fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data)
    .catch(function(err) {
      console.log('Fetch Error: ', err);
    });
  return data;
}

export const getElementById = async (idPhoto) =>{
  let queryString = '/photos?' + `id=${idPhoto}`;
  let endpoint = `${CMSAPI}${queryString}`;
  let element = await fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data[0])
    .catch(function(err) {
      console.log('Fetch Error: ', err);
    });
  return element;
}
