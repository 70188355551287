import React, { useEffect } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import Home from './Home/Home.js';
import Services from './Services/Services.js';
import Projects from './Projects/Projects.js';
import Clients from './Clients/Clients.js'
import Contact from './Contact/Contact.js'
import ButtonTop from 'Components/ButtonTop/ButtonTop.js'
import DetectMobile from 'Helpers/DetectMobile.js';
import './Landing.scss';

import {
  useLocation
} from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Landing =  (props) => {
  let offsetTop = -35;
  if(DetectMobile()){
    offsetTop = -120;
  }

  let top = React.createRef();
  let homeRef = React.createRef();
  let servicesRef = React.createRef();
  let projectsRef = React.createRef();
  let clientsRef = React.createRef();
  let contactRef = React.createRef();
  let query = useQuery().get('section');
  let queryType = useQuery().get('type');
  useEffect(()=>{
    if(queryType !== 'scrolled'){
      switch (query) {
        case 'start':
          scrollToComponent(homeRef.current, {align: 'top', offset: offsetTop});
          break;
        case 'offer':
          scrollToComponent(servicesRef.current, {align: 'top', offset: offsetTop});
          break;
        case 'portfolio':
          scrollToComponent(projectsRef.current, {align: 'top', offset: offsetTop});
          break;
        case 'contact':
          scrollToComponent(contactRef.current, {align: 'top', offset: offsetTop});
          break;
        default:
          scrollToComponent(homeRef.current, {align: 'top', offset: offsetTop});
          break;
      }
    }
  },[query, queryType, homeRef, servicesRef, projectsRef, clientsRef, contactRef, offsetTop]);

  const scrollToTop = () => {
    scrollToComponent(top.current, {align: 'top', offset: offsetTop});
  }

  return (
    <React.Fragment>
      <div ref={top} />
      <div ref={homeRef}><Home/></div>
      <div ref={servicesRef}><Services/></div>
      <div ref={projectsRef}><Projects/></div>
      <div ref={clientsRef}><Clients/></div>
      <div ref={contactRef}><Contact/></div>
      <ButtonTop handleClick={scrollToTop}/>
    </React.Fragment>
  );
}

export default Landing;
