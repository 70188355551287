import React from 'react';
import './Footer.scss';

const Footer =  () => {
  return (
    <div id="footer">
      <div className="logo-footer">&#xe910;</div>
      <div className="text-footer">© 2T19 Moryc Studio</div>
    </div>
  );
}

export default Footer;
