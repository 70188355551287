import React from 'react';
import './ServiceBox.scss';
import {useStateValue} from 'Contexts/LanguageContext';
import {Link} from 'react-router-dom';
const parse = require('html-react-parser');

const ServiceBox =  (props) => {
  const [{ language }] = useStateValue();
  return (
    <Link id="service-box" to={props.service.to}>
      {parse(`<div class="logo-service">${props.service.icon[language]}</div>`)}
      <div className="text-service">{props.service.name[language]}</div>
    </Link>
  );
}

export default ServiceBox;
