import React from 'react';
import Form from './Form/Form.js';
import MapMoryc from './MapMoryc/MapMoryc.js';
import './Contact.scss';
import VisibilitySensor from 'react-visibility-sensor';

import {
  withRouter
} from 'react-router-dom';


import {useStateValue} from 'Contexts/LanguageContext';

const text = {
  title : {
    en: "CONTACT",
    pl: "KONTAKT"
  }
}

const Contact =  (props) => {
  const [{ language }] = useStateValue();
  const changeVisibility = (isVisible)=>{
    if(isVisible){
      props.history.push('landing?section=contact&type=scrolled')
    }
  }

  return (
    <VisibilitySensor partialVisibility onChange={changeVisibility}>
      <div id="contact">
        <div className="title">{text.title[language]}</div>
        <div className="container-elements-contact">
          <Form />
          <MapMoryc />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default withRouter(Contact);
