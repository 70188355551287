import React, {useState, useEffect} from 'react';
import ElementGrid from './ElementGrid/ElementGrid.js';
import DetectMobile from 'Helpers/DetectMobile.js';
import './Gallery.scss';
import FakeElementsGenerator from './FakeElementsGenerator';
import { saveAs } from 'file-saver';

const saveDataJSON = (client, arr) =>{
  var data = {
      array: arr
  };
  var fileName = `${client.toLowerCase()}.json`;

  // Create a blob of the data
  var fileToSave = new Blob([JSON.stringify(data)], {
      type: 'application/json',
      name: fileName
  });

  // Save the file
  saveAs(fileToSave, fileName);

}

const GalleryArrange = (props)=>{
  let elements = [...props.elements];
  var elementsfiltered = elements.filter(function (el) {
    return el != null;
  });
  return(
    <React.Fragment>
      {elementsfiltered.map((element, idx)=>{
        return(
          <ElementGrid key={idx} element={element} gridDescription={props.gridDescription} production={props.production} changeImage={props.changeImage} deleteElement={props.deleteElement} squares={props.squares} nopadding={props.nopadding}/>
        )
      })}
    </React.Fragment>
  )
}

const Gallery =  (props) => {
  const [elementsG, setElementsG] = useState([]);
  const [elementsO, setElementsO] = useState([]);

  let gridDescription = props.GridDescription;
  let containerStyleMobile = {
    width: `${gridDescription.maxWidthDesktop}px`,
    maxWidth: `${gridDescription.maxWidth}vw`,
    minHeight: `${gridDescription.elementHeightMobile*gridDescription.yDivisionsMobile}px`
  }
  let containerStyleDesktop = {
    width: `${parseInt(gridDescription.maxWidthDesktop)+2}px`,
    maxWidth: `${gridDescription.maxWidth}vw`,
    minHeight: `${gridDescription.elementHeightDesktop*gridDescription.yDivisionsDesktop}px`
  }
  let OriginalGalleryElements = [...props.elements];
  let GalleryElements = [...props.elements];
  if(!props.production){
    GalleryElements = [...props.elements].concat(FakeElementsGenerator(gridDescription))
  }

  let mobile = DetectMobile();

  const changeImage = (e, element, width, height)=>{
    let file = e.target.files[0];
    let filename = file.name;
    let newElement = Object.assign({}, element);
    newElement.type = 'real';
    newElement.name = props.id;
    newElement.action = 'modal';
    newElement.backgroundMobile = `/media/img/projects/${props.id.toLowerCase()}/${filename}`;
    newElement.position.mobile.xSpaces = parseInt(width);
    newElement.position.mobile.ySpaces = parseInt(height);
    delete newElement.position.desktop;
    let nGalleryElements = [...elementsG].concat([newElement]);
    let nOriginalGalleryElements = [...elementsO].concat([newElement]);
    setElementsG(nGalleryElements);
    setElementsO(nOriginalGalleryElements);
    saveDataJSON(props.id, nOriginalGalleryElements);
  }

  useEffect(()=>{
    setElementsO(OriginalGalleryElements);
    setElementsG(GalleryElements);
  },[props])

  const deleteElement = (element)=>{
    let nGalleryElements = [...elementsG];
    nGalleryElements[nGalleryElements.indexOf(element)].type = 'deleted';
    nGalleryElements[nGalleryElements.indexOf(element)].name = 'fake';
    nGalleryElements[nGalleryElements.indexOf(element)].action = undefined;
    nGalleryElements[nGalleryElements.indexOf(element)].backgroundMobile = undefined;
    nGalleryElements[nGalleryElements.indexOf(element)].backgroundDesktop = undefined;
    nGalleryElements[nGalleryElements.indexOf(element)].position.mobile.xSpaces = parseInt(1);
    nGalleryElements[nGalleryElements.indexOf(element)].position.mobile.ySpaces = parseInt(1);
    delete nGalleryElements[nGalleryElements.indexOf(element)].position.desktop;
    setElementsG(nGalleryElements);
    let nelementsO = [...elementsO];
    nelementsO.splice(nelementsO.indexOf(element), 1);
    setElementsO(nelementsO);
    saveDataJSON(props.id, nelementsO);
  }

  return (
    <div className={`gallery-container ${mobile ? 'mobile' : 'desktop'}`} style={mobile ? containerStyleMobile : containerStyleDesktop}>
      <GalleryArrange elements={elementsG} gridDescription={gridDescription} production={props.production} changeImage={changeImage} deleteElement={deleteElement} squares={gridDescription.squares} nopadding={gridDescription.nopadding}/>
    </div>
  );
}

export default Gallery;
