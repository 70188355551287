import React, {useEffect, useState} from 'react';
import {getElementById} from 'Helpers/CMSHelper';
import SingleBook from './SingleBook';
import './VerticalBook.scss';

const getImages = async (orderedList) => {
  return Promise.all(orderedList.map(idPhoto => getElementById(idPhoto)))
}

const VerticalBook =  (props) => {
  const [elements, setElements] = useState([])
  useEffect(()=>{
    (async ()=>{
      if(props.grid.order){
        let order = props.grid.order;
        getImages(order).then((newElements)=>{
          setElements(newElements);
        })
      }
    })();
  },[props.grid])

  return (
    <div id="container-vertical-book">
      {elements.map((element, idx)=>{
        let link = `https://morycstudiob.oracliom.com${element.imgMini.url}`
        return (
          <SingleBook key={idx} link={link}/>
        )
      })}
    </div>
  );
}

export default VerticalBook;
