import React, { useState, useEffect } from 'react';
import ItemNavBar from './ItemNavBar/ItemNavBar';
import Burguer from './Burguer/Burguer';
import ButtonBack from './ButtonBack/ButtonBack';
import LogoModified from './LogoModified/LogoModified.js';
import './NavBar.scss';
import DetectMobile from 'Helpers/DetectMobile';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import items from './Items';
import {useStateValue} from 'Contexts/LanguageContext';

import {
  useLocation
} from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function NavBar() {
  const [navbarToggled, setToggleState] = useState(false);
  const [itemsDisplayed, setItemsDisplayed] = useState([]);
  const [{ language }, dispatch] = useStateValue();

  const [zone, setCurrentZone] = useState(undefined);

  let query = useQuery().get('section');

  const toggleNavbar = () =>{
    setToggleState(!navbarToggled);
  }

  useEffect(()=>{
    setCurrentZone(query);
    let mobile = DetectMobile();
    if(mobile){
      setItemsDisplayed(items.slice(1))
    }else{
      setItemsDisplayed(items)
    }
  },[query])

  return (
    <div id="navbar">
      <LogoModified />
      <ButtonBack />
      <Burguer toggleNavbar={toggleNavbar} navbarToggled={navbarToggled}/>
      {navbarToggled && (
        <React.Fragment>
          <div className="background-grey-navbar" onClick={toggleNavbar}/>
          <div className="container-items-navbar-phone">
            <LanguageSelector />
            {itemsDisplayed.map((item)=> <ItemNavBar key={item.name} item={item} toggleNavbar={toggleNavbar} selected={zone === item.id}/>)}
          </div>
        </React.Fragment>
      )}
      <div className="container-items-navbar-large">
        {itemsDisplayed.map((item)=> <ItemNavBar key={item.id} item={item} selected={zone === item.id}/>)}
        <LanguageSelector />
      </div>
    </div>
  );
}

export default NavBar;
