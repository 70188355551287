import React from 'react';
import './Logo.scss';
import {
  Link
} from "react-router-dom";

const Logo = (props) => {
  return(
    <Link to="/landing?section=start" id="logo-container">
      <div alt="logo" className="logo">&#xe910;</div>
    </Link>
  )
}

export default Logo;
