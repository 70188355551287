import React from 'react';
import './ServicesDescription.scss';
import {useStateValue} from 'Contexts/LanguageContext';
const parse = require('html-react-parser');

const texts = {
  textA: {
    en:"One image can replace thousands of words. We live in the age of the picture letters, today what counts is a message that is easy to understand in a split second. Every day we are flooded with visual information, symbols, pictograms, icons or other signs. That is why their <b> readability and quality are important! </b>",
    pl:"Jeden obraz zastępuje tysiące słów. Żyjemy w erze pisma obrazkowego, zalewani symbolami, piktogramami, ikonami i innymi znakami. Nadmiar bodźców i wrażeń wizualnych rozprasza naszą uwagę, dlatego dziś liczy się przekaz łatwy do zrozumienia w ułamku sekundy. Wysoka <b>jakość i czytelność</b> naszych projektów wyróżnia je z otaczającego nas smogu komunikacyjnego."
  },
  textB: {
    pl: "Budujemy tożsamość i wizerunek marki. Specjalizujemy się w tworzeniu systemów identyfikacji wizualnej, projektowaniu znaków graficznych, logotypów, layout’ów wydawnictw, typografii, ilustracji oraz w profesjonalnej fotografii reklamowej. Dbamy o czytelność infografik wykorzystywanych w mediach. Wiemy, że to ona decyduje, czy odbiorca dostrzeże przekaz, czy go w ogóle nie zauważy<b> :)</b>",
    en: "We create visual identification systems, design graphic logos, layouts of publishing houses, typographies, illustrations and photography. We care about the readability of the infographic used in the media, because according to its quality, the recipient will be able to see and read it, or not notice it at all <b> :) </b>"
  }
}
const ServicesDescription =  () => {
  const [{ language }] = useStateValue();

  return (
    <div id="services-description-container">
      <div className="description-container">
        {parse(texts.textA[language])}
      </div>
      <div className="description-container">
        {parse(texts.textB[language])}
      </div>
    </div>
  );
}

export default ServicesDescription;
