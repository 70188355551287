import React, { useState, useEffect } from 'react';
import './Home.scss';
import VisibilitySensor from 'react-visibility-sensor';
import {useStateValue} from 'Contexts/LanguageContext';

import {
  withRouter
} from 'react-router-dom';

const text = {
  description : {
    en: 'Advertising Agency • Design • Photography • CI Systems • Printing • Websites',
    pl: 'Agencja Reklamowa • Design • Fotografia • systemy CI • Druk • strony www'
  }
}

const Home = (props) => {
  const [videoBlob, setVideoBlob] = useState('');
  const [{ language }] = useStateValue();

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      props.history.push('landing?section=start&type=scrolled')
    }
  }

  useEffect(() => {
    var myRequest = new Request('/media/videos/file.mp4');
    fetch(myRequest)
    .then(response => response.blob())
    .then(function(myBlob) {
      let objectURL = URL.createObjectURL(myBlob);
      setVideoBlob(objectURL);
    });
  },[]);
  return (
    <VisibilitySensor partialVisibility onChange={changeVisibility}>
      <div id="home-container">
        {videoBlob!=='' ? (
          <video src={videoBlob} className="background-video" autoPlay={videoBlob !== ''} loop />
        ):(
          <img alt="home" className="background-video" src="/media/videos/video_placeholder.jpg"></img>
        )}
        <div className="overlay-lines" />
        <div className="moryc-logo">&#xe910;</div>
        <div className="moryc-title">MORYC STUDIO</div>
        <div className="description">{text.description[language]}</div>
      </div>
    </VisibilitySensor>
  );
}

export default withRouter(Home);
