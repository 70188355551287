const items = [
  {
    id: 'start',
    name: {
      en: 'Moryc Studio',
      pl: 'Moryc Studio'
    },
    link: '/landing?section=start'
  },
  {
    id: 'offer',
    name: {
      en: 'Offer',
      pl: 'Oferta'
    },
    link: '/landing?section=offer'
  },
  {
    id: 'portfolio',
    name: {
      en: 'Portfolio',
      pl: 'Portfolio'
    },
    link: '/landing?section=portfolio'
  },
  {
    id: 'contact',
    name: {
      en: 'Contact',
      pl: 'Kontakt'
    },
    link: '/landing?section=contact'
  }
]

export default items;
