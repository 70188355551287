import React from 'react';
import './LogoModified.scss';
import {
  Link
} from "react-router-dom";
import {useStateValue} from 'Contexts/LanguageContext';

const text = {
  subtitle : {
    pl: 'AGENCJA REKLAMOWA',
    en: 'ADVERTISING AGENCY'
  }
}
const LogoModified = (props) => {
  const [{ language }] = useStateValue();

  return(
    <Link id="logo-modified-container" to="/landing?section=offer" >
      <div className="flow-hor">
        <div className="logo">&#xe910;</div>
        <div className="logo-text">Moryc Studio</div>
      </div>
      <div className="text-title">{text.subtitle[language]}</div>
    </Link>
  )
}

export default LogoModified;
