import React from 'react';
import {useStateValue} from 'Contexts/LanguageContext';
import './LanguageSelector.scss';

function LanguageSelector() {
  const [{ language }, dispatch] = useStateValue();

  const modifyLanguage = (val) =>{
    dispatch({
        type: 'UpdateLanguage',
        language: val
      })
  }
  return (
    <div className="language-container">
      {['en', 'pl'].map((lang)=>{
        return <div className={`language-selector ${language === lang ? ('selected'):('')}`} key={lang} onClick={()=>{modifyLanguage(lang)}}>{lang.toUpperCase()}</div>
      })}
    </div>
  );
}

export default LanguageSelector;
