import React from 'react';
import ServiceBox from './ServiceBox/ServiceBox.js';
import './ServicesList.scss';
import {useStateValue} from 'Contexts/LanguageContext';

const ServicesList =  () => {
  const [{ language }] = useStateValue();
  const services = [
    {name: {en:'Photography', pl: 'fotografia'}, icon: {en:'advertisingphotography' , pl:'fotografiareklamowa'}, to: '/projects/fotografia'},
    {name: {en:'Logos', pl: 'logo'}, icon: {en:'logodesigns' , pl:'projektowanielogo'}, to: '/projects/logo'},
    {name: {en:'Publishing', pl: 'wydawnictwa'}, icon: {en:'publishing' , pl:'wydawnictwa'}, to: '/projects/publishing'},
    {name: {en:'Websites', pl: 'www'}, icon: {en:'webdesign' , pl:'stronywww'}, to: '/projects/www'},
    {name: {en:'Visualizations', pl: 'wizualizacje'}, icon: {en:'3dvisualizations' , pl:'wizualizacje3d'}, to: '/projects/visualizations'},
    {name: {en:'Branding', pl: 'branding'}, icon: {en:'branding' , pl:'budowaniemarki'}, to: '/projects/branding'}
  ]

  return (
    <div id="container-services-list">
      {services.map((service)=>{
        return (
            <ServiceBox key={service.name[language]} service={service}/>
        )
      })}
    </div>
  );
}

export default ServicesList;
