  import React, {useState, useEffect} from 'react';
import ConditionalLink from './ConditionalLink.js';
import DetectMobile from 'Helpers/DetectMobile.js';
import ContentLoader from 'react-content-loader';
import './ElementGrid.scss';
import VisibilitySensor from 'react-visibility-sensor';
import CalculateData from './CalculateData';
import LazyImageLoader from 'Helpers/LazyImageLoader';
import PersonalizedModal from './PersonalizedModal/PersonalizedModal';
import Modal from 'react-modal';
import {CMSURL} from 'GlobalVariables/CMSURL';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '90vw',
    maxWidth              : '90vw',
    height                : '90vh',
    maxHeight             : '90vw'
  }
};

Modal.setAppElement('#root');

const ElementGrid =  (props) => {
  let mobile = DetectMobile();
  let [element, styleMobile, styleDesktop, xPosMob, xPosDesk, yPosMob, yPosDesk] =  [...CalculateData(props)];
  const [imageBlob, setImageBlob] = useState('');
  const [imageBlobBig, setImageBlobBig] = useState('');
  const [modalIsOpen,setIsOpen] = useState(false);
  const [width,changeWidth] = useState(1);
  const [height,changeHeight] = useState(1);

  const updateImage = async (element)=>{
    if(element.type !== "fake"){
      let linkImgMini = `${CMSURL}${element.imgMini.url}`;
      let newBloblImg = await LazyImageLoader(linkImgMini);
      setImageBlob(newBloblImg);
      let linkImgBig = `${CMSURL}${element.imgBig.url}`;
      let newBloblImgBig = await LazyImageLoader(linkImgBig);
      setImageBlobBig(newBloblImgBig);
    }
  }

  let type = props.element.type;
  const onChangeVisibility = async (isVisible) => {
    if(isVisible && !imageBlob && type === 'real'){
      updateImage(props.element);
    }
  }

  const toggleModal = ()=>{
    if(!modalIsOpen){
      setIsOpen(true);
    }else{
      setIsOpen(false);
    }
  }

  function closeModal(){
    setIsOpen(false);
  }

  const changeImageHandler = (e)=>{
    props.changeImage(e, props.element, width, height);
  }

  let styleSquares = (props.element.type ===  'fake') ? (
    {
      'display': 'none'
      }
  ):(
    {
      'position': 'relative',
      'width': '220px',
      'margin': '0'
    }
  )

  return (
    <React.Fragment>
      <PersonalizedModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        image={imageBlobBig}
        element={props.element}
      />
      <VisibilitySensor onChange={onChangeVisibility}>
        <ConditionalLink link={element.link} className={`element-grid ${mobile ? ('phone'):('desktop')} ${(element.type === 'fake') ? ('back-scene'):('')}`} style={mobile ? (styleMobile):(styleDesktop)} action={element.action} showModal={toggleModal} element={element}>
          {(element.type !== 'fake') &&
            <React.Fragment>
              {imageBlob ? (
                <div className={`normal-rectangle container-real ${props.nopadding ? ('no-border'):('')}`} style={{'backgroundImage': `url('${imageBlob}')`}}>
                  {(!props.production) && <i className="material-icons delete-element" onClick={()=>props.deleteElement(element)}>close</i>}
                  <div className='container-text'>{(props.element.action!=='modal' && !props.squares) && props.element.name}</div>
                </div>
                ):(<div className='loader' />)
              }
              </React.Fragment>
          }
          {(!props.production && !props.squares) &&
            <div className={`container-fake ${props.nopadding ? ('no-border'):('')}`}>
              {`Position \n(${mobile ? (xPosMob):(xPosDesk)}, ${mobile ? (yPosMob):(yPosDesk)})`}
              <input type="file" id="picture-gallery-upload" className="button-add-picture" onChange={changeImageHandler} />
              <input type="number" onChange={(e)=> changeWidth(e.target.value)} placeholder="Width"/>
              <input type="number" onChange={(e)=> changeHeight(e.target.value)} placeholder="Height"/>
            </div>
          }
        </ConditionalLink>
      </VisibilitySensor>
    </React.Fragment>
  );
}

export default ElementGrid;
