import React, {useEffect, useState} from 'react';
import LazyImageLoader from 'Helpers/LazyImageLoader';
import './VerticalBook.scss';

const SingleBook =  (props) => {
  const [imgBlob, setImageBlob] = useState(undefined)
  useEffect(()=>{
    let link = props.link;
    (async ()=>{
      let newImageBlob = await LazyImageLoader(link);
      setImageBlob(newImageBlob);
    })();
  },[props.link])

  return (
    <React.Fragment>
      {imgBlob ? (
        <img alt={imgBlob} src={imgBlob} className="img-vertical-book"/>
      ):(
        <div className="loader"/>
      )}

    </React.Fragment>
  );
}

export default SingleBook;
