import React, {useState, useEffect} from 'react';
import DetectMobile from 'Helpers/DetectMobile.js';
import LazyImageLoader from 'Helpers/LazyImageLoader';
import CMSAPI from 'Helpers/EndPoints';
import {Link} from 'react-router-dom';
import './CardsList.scss';

const Cards =  (props) => {
  const [imgBlob, setImageBlob] = useState('');
  useEffect(()=>{
    (async ()=>{
      if(props.element.imgMini.url){
        let imgURL = `${CMSAPI}${props.element.imgMini.url}`
        let newImgBlog = await LazyImageLoader(imgURL);
        setImageBlob(newImgBlog);
      }
    })();
  },[props.element]);

  return (
    <Link to={props.element.link} className="square" style={{'backgroundImage': `url('${imgBlob}')`}}>
      {!imgBlob ? (
        <div className="loader" />
      ):(
        <div  className="cover"/>
      )}
    </Link>
  );
}

export default Cards;
