import React from 'react';
import './ButtonBack.scss';
import {
  withRouter
} from 'react-router-dom';

const ButtonBack = (props) => {
  const comeBack = ()=>{
    props.history.goBack();
  }

  return(
    <React.Fragment>
      {(props.history.location.pathname === '/landing') ? (
        <React.Fragment></React.Fragment>
      ):(
        <div id="button-back-container" onClick={comeBack}>
          <i className="material-icons burguer">keyboard_arrow_left</i>
        </div>
      )
      }
    </React.Fragment>

  )
}

export default withRouter(ButtonBack);
