import React from 'react';
import './Clients.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {useStateValue} from 'Contexts/LanguageContext';

import {
  withRouter
} from 'react-router-dom';


let text = {
  title:  {
    en: "OUR CLIENTS",
    pl: "WSPÓŁPRACA"
  }
}
const Clients = () => {
  const [{ language }] = useStateValue();
  return (
    <div id="clients">
      <div className="title">{text.title[language]}</div>
      <div className="slider-container">
        <Carousel showArrows={false} showStatus={false} showIndicators={false} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={4000} swipeable={false} stopOnHover={false}>
          {
            [...Array(12)].map((val,idx)=>{
              let link = `media/img/logo_client_3/logo klientow poziom-1-${String(idx+1).padStart(2, '0')}.png`;
              return (
                <img alt={link} src={link} key={link}/>
              )
            })
          }
      </Carousel>
      </div>
    </div>
  );
};

export default withRouter(Clients);
