import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import NavBar from './NavBar/NavBar.js';
import Footer from './Footer/Footer.js';
import Landing from './Landing/Landing.js';
import Projects from './Projects/Projects.js';
// Context Language
import { StateProvider } from 'Contexts/LanguageContext';
//Libs
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '31px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
}


const App =  () => {
  const initialState = {
    language: 'pl'
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UpdateLanguage':
        return {
          ...state,
          language: action.language
        };
      default:
        return state;
    }
  };


  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Router>
          <div id="app">
            <NavBar/>
            <Switch>
              <Route path="/landing">
                <Landing/>
              </Route>
              <Route path="/projects/:id">
                <Projects />
              </Route>
              <Route path="/">
                <Landing />
              </Route>
            </Switch>
            <Footer />
          </div>
        </Router>
      </AlertProvider>
    </StateProvider>
  );
}

export default App;
