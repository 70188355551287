import React from 'react';
import {
  Link
} from "react-router-dom";
import './ElementGrid.scss';

const ConditionalLink =  (props) => {
  let modal = (props.action === 'modal');
  const handleModal = ()=>{
    if(modal){
      props.showModal();
    }
  }
  return (
    <React.Fragment>
      {(props.link == undefined || props.link == null) ? (
        <div className={props.className} style={props.style} onClick={handleModal}>
          {props.children}
        </div>
      ):(
        <Link to={props.link} className={props.className} style={props.style}>
          {props.children}
        </Link>
      )}
    </React.Fragment>
  );
}

export default ConditionalLink;
