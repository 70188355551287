import React from 'react';
import './ItemNavBar.scss';
import Logo from '../Logo/Logo.js';
import {
  Link
} from "react-router-dom";
import {useStateValue} from 'Contexts/LanguageContext';

const ItemNavBar = (props) => {
  const [{ language }] = useStateValue();
  if(props.item.id === 'start'){
    return(
      <React.Fragment>
        <Logo />
        <Link to={props.item.link} onClick={props.toggleNavbar} id="item-nav-bar" className={(props.selected) ? 'selected-navbar-item' : ''}>{props.item.name[language]}</Link>
      </React.Fragment>
    )
  }else{
    return(
      <Link to={props.item.link} onClick={props.toggleNavbar} id="item-nav-bar" className={(props.selected) ? 'selected-navbar-item' : ''}>{props.item.name[language]}</Link>
    )
  }
}

export default ItemNavBar;
