const CalculateData = (props)=>{
  let element = props.element;
  let styleMobile = {left: `0%`, top: `0px`, width: `0%`, height: `0px`};
  let styleDesktop = {left: `0%`, top: `0px`, width: `0%`, height: `0px`};
  let xPosMob = 0;
  let yPosMob = 0;
  let xPosDesk = 0;
  let yPosDesk = 0;

  if(element.position){
    const boxWidthMobile = 100.0 / props.gridDescription.xDivisionsMobile; // Percentage %
    const boxHeightMobile = props.gridDescription.elementHeightMobile; // PX
    const boxWidthDesktop = 100.0 / props.gridDescription.xDivisionsDesktop; // Percentage %
    const boxHeightDesktop = props.gridDescription.elementHeightDesktop // PX

    //Dimentions Mobile
    let xMobile = (element.position.mobile.x) * boxWidthMobile;
    let yMobile = (element.position.mobile.y) * boxHeightMobile;
    let widthMobile = (element.position.mobile.xSpaces) * boxWidthMobile;
    let heightMobile = (element.position.mobile.ySpaces) * boxHeightMobile;
    let styleMobile = {left: `${xMobile}%`, top: `${yMobile}px`, width: `${widthMobile}%`, height: `${heightMobile}px`};

    //Dimentions Desktop
    let xDesktop = (element.position.mobile.x) * boxWidthDesktop;
    let yDesktop = (element.position.mobile.y) * boxHeightDesktop;
    let widthDesktop = (element.position.mobile.xSpaces) * boxWidthDesktop;
    let heightDesktop = (element.position.mobile.ySpaces) * boxHeightDesktop;
    let styleDesktop = {left: `${xDesktop}%`, top: `${yDesktop}px`, width: `${widthDesktop}%`, height: `${heightDesktop}px`};

    if(element.position.desktop){
      xDesktop = (element.position.desktop.x) * boxWidthDesktop;
      yDesktop = (element.position.desktop.y) * boxHeightDesktop;
      widthDesktop = (element.position.desktop.xSpaces) * boxWidthDesktop;
      heightDesktop = (element.position.desktop.ySpaces) * boxHeightDesktop;
      styleDesktop = {left: `${xDesktop}%`, top: `${yDesktop}px`, width: `${widthDesktop}%`, height: `${heightDesktop}px`};
    }

    // Backgrounds

    xPosMob = element.position.mobile.x;
    yPosMob = element.position.mobile.y;
    xPosDesk = xPosMob;
    yPosDesk = yPosMob;
    if(element.position.desktop){
      xPosDesk = element.position.desktop.x;
      yPosDesk = element.position.desktop.y;
    }
    return [element, styleMobile, styleDesktop, xPosMob, xPosDesk, yPosMob, yPosDesk];
  }else{
    return [element, styleMobile, styleDesktop, xPosMob, xPosDesk, yPosMob, yPosDesk];
  }
}
export default CalculateData
