import React from 'react';
import './PersonalizedModal.scss';

let text = {
  en: "Made by Moryc Studio",
  pl: "Realizacja Moryc Studio",
}
const PModal =  (props) => {
  return (
    <div id="personalized-modal">
      <div className="overlay" onClick={props.closeModal}/>
      <div className="centered-img-container">
        <i  onClick={props.closeModal} className="material-icons close-icon">close</i>
        <img img={props.img} src={props.img} className="centered-img" />
      </div>
      <div className="description-image">{props.language === 'en' ? (props.element.dsc_en):(props.element.dsc_pl)}</div>
    </div>
  );
}

export default PModal;
