const LazyImageLoader = async (img_lnk) => {
  let imageRequested = img_lnk;
  let myRequest = new Request(imageRequested);
  let blobResult = await fetch(myRequest)
  .then(response => response.blob())
  .then(function(myBlob) {
    let objectURL = URL.createObjectURL(myBlob);
    return objectURL;
  });
  return blobResult;
}

export default LazyImageLoader
