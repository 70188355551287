import React, {useState} from 'react';
import { useAlert } from 'react-alert';
import './Form.scss';
import {useStateValue} from 'Contexts/LanguageContext';
// const [{ language }] = useStateValue();

let text = {
  name: {
    en: "Name *",
    pl: "Imię *"
  },
  lastname: {
    en: "Lastname *",
    pl: "Nazwisko *"
  },
  message: {
    en: "Message",
    pl: "Wiadomość"
  },
  send: {
    en: "Send",
    pl: "Wyślij"
  },
  sendingMessage:{
    en: "Sending message",
    pl: "Wysyłanie wiadomości ..."
  },
  messageSent:{
    en: "Message sent",
    pl: "Wiadomość wysłana"
  },
  messageError:{
    en: "We are sorry. Your message could not be sent",
    pl: "Przepraszamy, nie mogliśmy wysłać wiadomości"
  }
}
const Form =  () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [message, setMessage] = useState('');
  const alert = useAlert();
  const [{ language }] = useStateValue();

  const sendMessage = ()=> {
    alert.show(text.sendingMessage[language],{
      type: 'success',
    });
    var formData = new FormData();
    formData.append('name', name);
    formData.append('lastname', lastname);
    formData.append('message', message);
    setName('');
    setLastname('');
    setMessage('');
    const googleSheetHost = 'https://script.google.com/macros/s/AKfycbwVHqdHTxa1EvsD6ZJy5hfj0WBRDmBpJ8pZV4ww/exec'
    fetch( googleSheetHost,{ method: 'POST', body: formData})
    .then((response) => {
      if (response.ok) {
        alert.show(text.messageSent[language],{
          type: 'success',
        });
      }else{
        alert.show(text.messageError[language],{
          type: 'error',
        });
      }
    });
  }

  return (
    <div id="form">
      <input onChange={(e)=> setName(e.target.value)} className="input-name-surname" placeholder={text.name[language]} value={name}/>
      <input onChange={(e)=> setLastname(e.target.value)} className="input-name-surname" placeholder={text.lastname[language]} value={lastname}/>
      <textarea onChange={(e)=> setMessage(e.target.value)} className="input-name-message" placeholder={text.message[language]} rows="6" value={message}/>
      <button className="button" onClick={sendMessage}>{text.send[language]}</button>
    </div>
  );
}

export default Form;
