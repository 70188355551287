import React from 'react';
import ServicesList from './ServicesList/ServicesList.js';
import ServicesDescription from './ServicesDescription/ServicesDescription.js';
import {useStateValue} from 'Contexts/LanguageContext';
import './Services.scss';
import VisibilitySensor from 'react-visibility-sensor';

import {
  withRouter
} from 'react-router-dom';


const text = {
  title: {
    en: 'Services',
    pl: 'Zakres Usług'
  }
}

const Services = (props) => {
  const [{ language }] = useStateValue();

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      props.history.push('landing?section=offer&type=scrolled')
    }
  }

  return (
      <div id="services">
        <div className="container-background" />
        <div className="grey title">{text.title[language]}</div>
        <ServicesList />
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
          <div className="container-services-description">
            <ServicesDescription />
          </div>
        </VisibilitySensor>
      </div>
  );
};

export default withRouter(Services);
