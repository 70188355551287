import React from 'react';
import './Burguer.scss';

const Burguer = (props) => {
  const toggleNavbar = () => {
    props.toggleNavbar();
  }

  return(
    <div id="burguer-container" onClick={toggleNavbar}>
      <i className="material-icons burguer">{props.navbarToggled ? ("close"):("menu")}</i>
    </div>
  )
}

export default Burguer;
