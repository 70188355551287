import React, {useEffect, useState} from 'react';
import Gallery from 'Components/Gallery/Gallery.js';
import {useStateValue} from 'Contexts/LanguageContext';
import VisibilitySensor from 'react-visibility-sensor';
import {getProject, getElements} from 'Helpers/CMSHelper';
import Loader from 'react-loader-spinner';
import {
  withRouter
} from 'react-router-dom';


import './Projects.scss';
const text = {
  title: {
    en: "OUR PROJECTS",
    pl: "WYBRANE REALIZACJE"
  }
}



const Projects =  (props) => {
  const [{ language }] = useStateValue();
  const [photos, setPhotos] = useState([]);
  const [gridDsc, setGridDsc] = useState(undefined)

  const changeVisibility = (isVisible)=>{
    if(isVisible){
      props.history.push('landing?section=portfolio&type=scrolled')
    }
  }

  useEffect(()=>{
    setPhotos([]);
    (async ()=>{
      let id = 'portfolio';
      let photos = await getElements(id);
      let projectJson = await getProject(id);
      setPhotos(photos);
      setGridDsc(projectJson.grid);
    })();
  },[])

  return (
    <VisibilitySensor partialVisibility onChange={changeVisibility}>
      <div id="projects-landing">
        <div className="title">{text.title[language]}</div>
        {(photos.length !== 0 && gridDsc !== undefined) ?
          (
            <Gallery GridDescription={gridDsc} elements={photos} production id={'portfolio'}/>
          ):(
            <Loader
               type="Grid"
               color="#DDD"
               height={100}
               width={100}
               timeout={3000} //3 secs
            />
          )
        }
      </div>
    </VisibilitySensor>
  );
};

export default withRouter(Projects);
