import React, {useState, useEffect} from 'react';
import LazyImageLoader from 'Helpers/LazyImageLoader';
import Card from './Card';

import './CardsList.scss';

const CardsList =  (props) => {
  return (
    <div id="container-cards-list">
      {
        props.elements.map((element, idx)=> <Card key={idx} element={element} />)
      }
    </div>
  );
}

export default CardsList;
