import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useStateValue} from 'Contexts/LanguageContext';
import PModal from './PModal';
import './PersonalizedModal.scss';

const PersonalizedModal =  (props) => {
  const [{ language }] = useStateValue();

  useEffect(()=>{
    if(props.isOpen){
      ReactDOM.render(<PModal element={props.element} language={language} closeModal={props.onRequestClose} img={props.image}/>, document.getElementById('modal'));
    }else{
      ReactDOM.render(<div></div>, document.getElementById('modal'));
    }
  },[props.isOpen])
  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default PersonalizedModal;
