const FakeElementsGenerator = (gridDSC) => {
  let fakeMobile = [];
  let fakeDesktop = [];
  for (let i = 0; i < gridDSC.xDivisionsMobile; i++){
    for (let j = 0; j < gridDSC.yDivisionsMobile; j++){
      fakeMobile.push({type: 'fake', name: "fake", position: {mobile: {x: i, y: j, xSpaces: 1, ySpaces: 1}}})
    }
  }
  for (let i = 0; i < gridDSC.xDivisionsDesktop; i++){
    for (let j = 0; j < gridDSC.yDivisionsDesktop; j++){
      fakeDesktop.push({type: 'fake', name: "fake", position: {desktop: {x: i, y: j, xSpaces: 1, ySpaces: 1}}})
    }
  }
  return fakeMobile.map((obj,idx)=>{
    obj.position = Object.assign(obj.position, fakeMobile[idx].position);
    return obj;
  })
};

export default FakeElementsGenerator;
