import React from 'react';
import GoogleMapReact from 'google-map-react';
import DetectMobile from 'Helpers/DetectMobile.js';
import './MapMoryc.scss';
import {useStateValue} from 'Contexts/LanguageContext';
// const [{ language }] = useStateValue();

const text = {
  phone: {
    pl: "kom.",
    en: "tel."
  }
}
const Marker = props => {
  return <i className="material-icons pin">location_on</i>
}

const MapMoryc =  () => {
  const [{ language }] = useStateValue();
  const defaultProps = {
    center: {
      lat: 50.2774135,
      lng: 19.0222588
    },
    zoom: 16
  };

  let width = '500px'
  if(DetectMobile()){
    width = `${((window.innerWidth > 0) && (window.innerWidth - 60))}px`;
  }

  return (
    <div id="map">
      <div className="map-container" style={{ height: '200px', width: width }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyATvB8t64X7q-IY7om2HagdmSqbqR1XoUg' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
        </GoogleMapReact>
      </div>
      <div className="data-address first">Moryc Studio</div>
      <div className="data-address">40-156 Katowice</div>
      <div className="data-address">Al. Wojciecha Korfantego 126/1</div>
      <div className="data-address">e-mail: <a href="mailto:biuro@moryc.com.pl" className="data-address">biuro@moryc.com.pl</a></div>
      <div className="data-address">{text.phone[language]} <a href="tel:+48604542652" className="data-address">+48 604 542 652</a></div>
      <div className="data-address">{text.phone[language]} <a href="tel:+48602215250" className="data-address">+48 602 215 250</a></div>
    </div>
  );
}

export default MapMoryc;
