import React, {useEffect, useState} from 'react';
import scrollToComponent from 'react-scroll-to-component';
import Gallery from 'Components/Gallery/Gallery.js';
import ButtonTop from 'Components/ButtonTop/ButtonTop.js';
import VerticalBook from 'Components/VerticalBook/VerticalBook.js';
import CardsList from 'Components/CardsList/CardsList.js';
import {useStateValue} from 'Contexts/LanguageContext';
import {getProject, getElements} from 'Helpers/CMSHelper';
import Loader from 'react-loader-spinner'

import {
  useParams,
  withRouter
} from "react-router-dom";
import './Projects.scss';

const parse = require('html-react-parser');

const text = {
  back: {
    en: "< Back",
    pl: "< Wróć"
  }
}

const Projects =  (props) => {
  let { id } = useParams();
  let projectsContainer = React.createRef();
  const [{ language }] = useStateValue();
  const [data, setData] = useState(undefined);
  const [grid, setGrid] = useState(undefined);
  const [elements, setElements] = useState(undefined);
  const goBack = ()=>{
    props.history.goBack();
  }

  const scrollToTop = ()=>{
    scrollToComponent(projectsContainer.current, {align: 'top'})
  }

  useEffect(()=>{
    scrollToTop();
    setElements([]);
    setData(undefined);
    setGrid(undefined);
    (async()=>{
      let projectJson = await getProject(id);
      let newElements = await getElements(id);
      setData(projectJson.data);
      setGrid(projectJson.grid);
      setElements(newElements);
    })();
  },[id])

  return (
    <div ref={projectsContainer} id="projects">
      {(data !== undefined && grid !== undefined) ? (
        <React.Fragment>
          <div className="title">{data.name[language]}</div>
          <div className="container-subtitle-button-back">
            <div onClick={goBack} className="button-back">{text.back[language]}</div>
            <div className="subtitle">{data.subtitle[language]}</div>
          </div>
            {grid.descriptionActive === true ? (
              <div className="container-description">{data.description[language]}</div>
            ):(
              <div className="container-list">
                <ul className="list-services-text">
                  {data.list.map((text, idx)=>{
                    return(<li key={idx}>{parse(text[language])}</li>)
                  })}
                </ul>
              </div>
            )}
          {elements && (
            <div className="container-graphics">
              {(()=>{
                  switch(grid.type) {
                    case 'vertical-book':
                      return <VerticalBook elements={elements} grid={grid}/>;
                    case 'cards-list':
                      return <CardsList elements={elements} id={id}/>;
                    case 'mosaic-grid':
                      return <Gallery GridDescription={grid} elements={elements} name={data.name} id={id} production/>;
                    default:
                      return <React.Fragment />;
                }
              })()}
            </div>
          )
          }
        </React.Fragment>
      ):(
        <div className="container-loader-project">
          <Loader
             type="Grid"
             color="#DDD"
             height={100}
             width={100}
             timeout={3000} //3 secs
          />
        </div>
      )}
      <ButtonTop handleClick={scrollToTop}/>
    </div>
  );
}

export default withRouter(Projects);
